import React from 'react';
import styled from 'styled-components';
import { Description, H1, H3, Subtitle5 } from '@/components/Typography';
import ButtonDefault from '@/components/ButtonDefault';
import viewport1Img from '../../assets/img/orderInvoiceGenerationVp1.png';
import Section from '@/components/Section';
import FirstViewport from '@/components/FirstViewport';
import StepBlueCheckCard from '@/components/StepBlueCheckCard';
import ReversibleCardsComponent from '@/components/ReversibleCardsComponent';
import TickedItems from '@/components/TickedItems';
import { OrderInvoiceGeneration } from '@/pages/order-and-invoice-generation';
import PhoneOpenBankingCard from '@/components/PhoneOpenBankingCard';
import BigPurpleCard from '@/components/BigPurpleCard';

const FirstVpWrapper = styled.div`
  background: linear-gradient(118.47deg, #f8f9fa 3.57%, #e4e6f0 100%);
`;

const SecondViewport = styled(Section)`
  background: linear-gradient(#ffffff, #e7edf3);
  padding-bottom: 80px;
  @media (max-width: 800px) {
    padding-bottom: 1rem;
  }
`;

const DefaultViewport = styled(SecondViewport)<{
  withoutPaddingTop?: boolean;
  withoutPaddingBottom?: boolean;
}>`
  background: transparent;
  ${({ withoutPaddingTop }) => withoutPaddingTop && `padding-top: 0;`}
  ${({ withoutPaddingBottom }) => withoutPaddingBottom && `padding-bottom: 0;`}
`;

const StyledDescription = styled(Description)`
  :first-of-type {
    margin-top: 1rem;
  }
  color: #495b6c;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
`;

const ViewPortsWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

const StepsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px;
  margin-top: 40px;
`;

const StyledButtonDefault = styled(ButtonDefault)`
  margin-top: 2rem;
`;

const Subtitle = styled(Subtitle5)`
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
`;

interface ThisContainerProps {
  content: OrderInvoiceGeneration;
}

const OrderInvoiceGenerationContainer: React.FunctionComponent<
  ThisContainerProps
> = ({
  content: { viewport1, viewport2, viewport3, viewport4, viewport5 },
}) => (
  <>
    <FirstVpWrapper>
      <FirstViewport
        img={viewport1Img}
        withRightPadding
        imagePadding="0 0 0 7.6rem"
      >
        <H1>
          <span className="accent-text">{viewport1.title1}</span>
          {` `}
          {viewport1.title2}
          {` `}
          <span className="accent-text">{viewport1.title3}</span>
          {` `}
          {viewport1.title4}
        </H1>
        <StyledDescription>{viewport1.description}</StyledDescription>
        <TickedItems textColor="#495B6C" items={viewport1.tickedDescription} />
        <StyledButtonDefault to={viewport1.button.url}>
          {viewport1.button.label}
        </StyledButtonDefault>
      </FirstViewport>
    </FirstVpWrapper>

    <ViewPortsWrapper>
      <DefaultViewport withoutPaddingBottom>
        <ReversibleCardsComponent cards={viewport2.cards} />
      </DefaultViewport>

      <SecondViewport style={{ paddingTop: 0 }}>
        <H3 style={{ margin: `0 auto 16px`, textAlign: `center` }}>
          {viewport3.title}
        </H3>
        <Subtitle>{viewport3.subTitle}</Subtitle>
        <StepsWrapper>
          {viewport3.carouselItems.map((item) => (
            <StepBlueCheckCard
              title={item.title}
              description={item.description}
            />
          ))}
        </StepsWrapper>
      </SecondViewport>

      <PhoneOpenBankingCard />

      <DefaultViewport withoutPaddingTop withoutPaddingBottom>
        <ReversibleCardsComponent cards={viewport4.cards} />
      </DefaultViewport>

      <DefaultViewport withoutPaddingTop>
        <BigPurpleCard
          title={viewport5.title}
          leftColumn={viewport5.leftColumn}
          rightColumn={viewport5.rightColumn}
          bottomText={viewport5.bottomText}
          button={viewport5.button}
          descriptionStyles={{
            fontSize: `18px`,
            fontWeight: 400,
            color: `#F4F7F9`,
          }}
        />
      </DefaultViewport>
    </ViewPortsWrapper>
  </>
);

export default OrderInvoiceGenerationContainer;
